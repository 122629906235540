import { createRouter, createWebHistory } from "vue-router";

// Import skills (and insert into AppPage Routing)
import valeRoutes from "../skills/vale/router";
import termsRoutes from "../skills/terms/router";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/AppPage.vue"),
    children: [
      // Insert skill routes here!
      ...termsRoutes,
      ...valeRoutes,
      {
        path: "dashboard",
        component: () => import("../components/DashboardComponent.vue"),
      },
      {
        path: "/welcome",
        name: "WelcomePage",
        component: () => import("../components/WelcomeContent.vue"),
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: () => import("../components/ProfileComponent.vue"),
      },
      {
        path: "admin",
        name: "AdminPage",
        component: () => import("../components/admin/AdminComponent.vue"),
      },
      {
        path: "organizations",
        name: "organizationsPage",
        component: () =>
          import("../components/organization/OrganizationsComponent.vue"),
      },
      {
        path: "organizations/view/:organizationId",
        name: "organizationView",
        component: () =>
          import("../components/organization/OrganizationView.vue"),
      },
      {
        path: "organizations/edit/:organizationId?",
        name: "organizationEdit",
        component: () =>
          import("../components/organization/OrganizationEdit.vue"),
      },
      {
        path: "groups/view/:groupId",
        name: "groupView",
        component: () => import("../components/organization/GroupView.vue"),
      },
      {
        path: "organizations/view/:organizationId/addgroup",
        name: "groupAdd",
        component: () => import("../components/organization/GroupEdit.vue"),
      },
      {
        path: "groups/edit/:groupId",
        name: "groupEdit",
        component: () => import("../components/organization/GroupEdit.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("../views/LoginPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
